import logo from "./logo.svg";
import "./App.css";
import AOS from "aos";

import logoBilhete from "./assets/imgs/logo.png";
import ReactOwlCarousel from "react-owl-carousel";
import SmoothScroll from "./utils/SmoothScroll";
import openStoreApp from "./utils/OpenStore";
import openLink from "./utils/openLink";
import Seo from "./components/Seo";

function App() {
  AOS.init();
  const currentYear = new Date().getFullYear();
  return (
    <div className="App">
      <Seo
        title={`Bilhete.app - Crie sua rifa online!`}
        keywords={`rifa digital, criação de rifas online, rifa virtual, plataforma de rifas, sistema de rifas, rifas com 10 milhões de números, bilhetes automáticos, gerenciar Campanhas, venda de rifas online, compra de rifas online, sorteios online, criar rifas, vender rifas, Suarifa.digital, rifas virtuais, sorteios digitais, suporte 24 horas, bilhete premiado, pagamentos via PIX, personalização de rifas, arrecadação online, financiamento coletivo`}
        url={`https://bilhete.app`}
        description={`Bilhete.app | Criar ações nunca foi tão facíl`}
        image={logoBilhete}
      />
      <SmoothScroll />
      <div id="preloader">
        <div id="loader"></div>
      </div>

      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand">
              <img src={logoBilhete} alt="Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                {/* 
            <i className="icofont-navigation-menu ico_menu"></i> --> */}
                <span className="toggle-wrap">
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                {/* <!-- secondery menu start --> */}
                <li className="nav-item has_dropdown">
                  <a className="nav-link" href="#home">
                    Home
                  </a>

                  {/*   <span className="drp_btn">
                    <i className="icofont-rounded-down"></i>
                  </span><div className="sub_menu">
                    <ul>
                      <li>
                        <a href="../01-App-Landing-Page-Defoult/index.html">
                          Home 1{" "}
                        </a>
                      </li>
                      <li>
                        <a href="../02-App-Landing-Page-Center-Hero/index.html">
                          {" "}
                          Home 2{" "}
                        </a>
                      </li>
                      <li>
                        <a href="../03-App-Landing-Page-Static-Hero/index.html">
                          Home 3
                        </a>
                      </li>
                      <li>
                        <a href="../04-App-Landing-Page-Static-Hero-Center/index.html">
                          Home 4
                        </a>
                      </li>
                      <li>
                        <a href="../05-App-Landing-Page-QR-Code-Hero/index.html">
                          Home 5
                        </a>
                      </li>
                      <li>
                        <a href="../06-App-Landing-Page-Video-Hero/index.html">
                          Home 6
                        </a>
                      </li>
                      <li>
                        <a href="../07-App-Landing-Page-Slider-Hero-Star-Animation/index.html">
                          Home 7
                        </a>
                      </li>
                      <li>
                        <a href="../08-App-Landing-Page-Slider-Hero-Green/index.html">
                          Home 8
                        </a>
                      </li>
                      <li>
                        <a href="../09-App-Landing-Page-Slider-Hero-Light-Green/index.html">
                          Home 9
                        </a>
                      </li>
                      <li>
                        <a href="../10-App-Landing-Page-Static-Hero-Center-Light-Orange-Hero/index.html">
                          Home 10
                        </a>
                      </li>
                      <li>
                        <a href="../11-App-Landing-Page-Static-Hero-Center-Blue/index.html">
                          Home 11
                        </a>
                      </li>
                      <li>
                        <a href="../12-App-Landing-Page-Center-Hero-Orange-Gradient/index.html">
                          Home 12
                        </a>
                      </li>
                      <li>
                        <a href="../13-App-Landing-Page-Slider-Hero-Circle-Animated-bg/index.html">
                          Home 13
                        </a>
                      </li>
                      <li>
                        <a href="../14-App-Landing-Page-QR-Code-Dark-Hero/index.html">
                          Home 14
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </li>

                {/*  <!-- secondery menu start --> */}

                <li className="nav-item">
                  <a className="nav-link" href="#about">
                    Sobre Nós
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#howworks">
                    Como Funciona
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#reviews">
                    Reviews
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#footer">
                    Contato
                  </a>
                </li>
                <li className="nav-item">
                  <div className="btn_block">
                    <a
                      className="nav-link dark_btn"
                      href="https://play.google.com/store/apps/details?id=app.bilhete&fbclid=fbclid"
                    >
                      Baixar Aplicativo
                    </a>
                    <div className="btn_bottom"></div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      {/*   <!-- Banner-Section-Start --> */}
      <section className="banner_section" id="home">
        {/*  <!-- container start --> */}
        <div className="container">
          {/* <!-- row start --> */}
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              {/* <!-- banner text --> */}
              <div className="banner_text">
                {/*  <!-- typed text --> */}
                <div className="type-wrap">
                  {/*  <!-- add static words/sentences here (i.e. text that you don't want to be removed)--> */}
                  <span id="typed" className="typed"></span>
                </div>
                {/*  <!-- h1 --> */}
                <h1>
                  Criar <span>rifas online</span> nunca foi tão fácil!
                </h1>
                {/*  <!-- p --> */}
                <p>
                  Crie, gerencie e participe de rifas digitais de forma rápida e
                  segura, tudo em um só aplicativo.
                </p>
              </div>

              {/* <!-- users --> */}
              <div className="used_app">
                <ul>
                  <li>
                    <img src="images/banavt1.png" alt="image" />
                  </li>
                  <li>
                    <img src="images/banavt2.png" alt="image" />
                  </li>
                  <li>
                    <img src="images/banavt3.png" alt="image" />
                  </li>
                  <li>
                    <a
                      href="#"
                      className="popup-youtube play-button"
                      data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&amp;mute=1"
                      data-toggle="modal"
                      data-target="#myModal"
                      title="XJj2PbenIsU"
                    >
                      <img src="fonts/play.svg" alt="img" />
                    </a>
                  </li>
                </ul>
                <h3>12M + Usuários</h3>
                <p>
                  O melhor aplicativo para criar suas rifas e<br /> sortear o
                  bilhete premiado.
                </p>
              </div>

              {/* <!-- app buttons --> */}
              <ul className="app_btn">
                <li>
                  <a href="https://play.google.com/store/apps/details?id=app.bilhete&fbclid=fbclid">
                    <img
                      className="blue_img"
                      src="images/googleplay.png"
                      alt="image"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=app.bilhete&fbclid=fbclid">
                    <img
                      className="blue_img"
                      src="images/appstorebtn.png"
                      alt="image"
                    />
                  </a>
                </li>
              </ul>
            </div>
            {/* 
        <!-- banner slides start --> */}
            <div className="col-lg-6 col-md-12">
              <div className="banner_slider">
                <div className="left_icon">
                  <img src="images/smallStar.png" alt="image" />
                </div>
                <div className="right_icon">
                  <img src="images/bigstar.png" alt="image" />
                </div>

                <ReactOwlCarousel
                  id="frmae_slider"
                  className="owl-carousel owl-theme"
                  loop
                  margin={10}
                  nav={false}
                  items={1}
                  autoplay
                  autoplayTimeout={3000}
                  autoplayHoverPause
                >
                  <div className="item">
                    <div className="slider_img">
                      <img src="images/bannerScreen.png" alt="image" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src="images/bannerScreen2.png" alt="image" />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src="images/bannerScreen3.png" alt="image" />
                    </div>
                  </div>
                </ReactOwlCarousel>
                <div className="slider_frame">
                  <img src="images/iphonescren.png" alt="image" />
                </div>
              </div>
            </div>

            {/* 
        <!-- banner slides end --> */}
          </div>
          {/* <!-- row end -->
      <!-- Spin Diveder Start --> */}
          <div
            className="spinBlock"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <span className="star">
              <img src="images/smallStar.png" alt="image" />
            </span>
            <div className="spin_box" id="scrollButton">
              <span className="downsign">
                <i className="icofont-long-arrow-down"></i>
              </span>
              <div className="spin-text">
                <img src="images/12mtext.png" alt="image" />
              </div>
            </div>
            <span className="star">
              <img src="images/smallStar.png" alt="image" />
            </span>
          </div>
          {/*   <!-- Spin Diveder End --> */}
        </div>
        {/*  <!-- container end --> */}
      </section>
      {/* <!-- Banner-Section-end --> */}

      {/* <!-- Task-App-Section-Start --> */}
      <section className="row_am task_app_section">
        {/* Passo 1: Criar sua Rifa */}
        <div className="task_block">
          <div className="dotes_blue">
            <img src="images/blue_dotes.png" alt="imagem" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className="task_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="frame_img">
                  <img src="images/feature1a.png" alt="imagem" />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src="images/feature1b.png"
                    alt="imagem"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="task_text">
                <div
                  className="section_title white_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <span className="title_badge">Passo 1</span>
                  <span className="icon">
                    <img src="images/feature-icon3.png" alt="ícone" />
                  </span>
                  <h2>Crie sua Rifa</h2>
                  <p>
                    Transforme sua ideia em uma rifa incrível em menos de 3
                    minutos! Personalize prêmios, cores e detalhes para deixá-la
                    única e pronta para impressionar seus participantes. Tudo de
                    forma prática e intuitiva diretamente pelo app. 🎟️✨
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Passo 2: Compartilhe o Link */}
        <div className="task_block">
          <div className="dotes_blue">
            <img src="images/blue_dotes.png" alt="imagem" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className="task_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="frame_img">
                  <img src="images/feature2a.png" alt="imagem" />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src="images/feature2b.png"
                    alt="imagem"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="task_text">
                <div
                  className="section_title white_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <span className="title_badge">Passo 2</span>
                  <span className="icon">
                    <img src="images/feature-icon2.png" alt="ícone" />
                  </span>
                  <h2>Compartilhe o Link</h2>
                  <p>
                    Depois de criar sua rifa, é hora de divulgar! Com a
                    Bilhete.app, você gera um link exclusivo para compartilhar
                    com seus amigos, familiares ou seguidores nas redes sociais.
                    Use o WhatsApp, Instagram, Facebook ou qualquer outra
                    plataforma para alcançar mais participantes. Quanto mais
                    pessoas você atingir, maior será o engajamento e as chances
                    de sucesso da sua rifa. 🌐📲🎟️
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Passo 3: Arrecade */}
        <div className="task_block">
          <div className="dotes_blue">
            <img src="images/blue_dotes.png" alt="imagem" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className="task_img"
                data-aos="fade-in"
                data-aos-duration="1500"
              >
                <div className="frame_img">
                  <img src="images/feature3a.png" alt="imagem" />
                </div>
                <div className="screen_img">
                  <img
                    className="moving_animation"
                    src="images/feature3b.png"
                    alt="imagem"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="task_text">
                <div
                  className="section_title white_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-delay="100"
                >
                  <span className="title_badge">Passo 3</span>
                  <span className="icon">
                    <img src="images/feature-icon1.png" alt="ícone" />
                  </span>
                  <h2>Arrecade</h2>
                  <p>
                    Acompanhe suas vendas em tempo real e veja os resultados da
                    sua rifa diretamente no app. Com a Bilhete.app, você recebe
                    os pagamentos de forma prática e segura via Pix, sem
                    complicações. Controle cada detalhe, visualize os
                    participantes e monitore os ganhos enquanto sua rifa ganha
                    força. Transforme sua ideia em arrecadação de maneira fácil
                    e eficiente! 💸📈🎟️
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Task-App-Section-end --> */}

      {/* <!-- Page Wraper --> */}
      <div className="page_wrapper" id="about">
        {/*  <!-- About Us Start--> */}
        <section className="about_section row_am">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <span className="title_badge mb-1">Sobre nós</span>
              <h2>
                Transforme ideias em <br />
                rifas incríveis com a{" "}
                <span>
                  <img src="images/mobileicon.png" alt="ícone" /> Bilhete.app
                </span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <ul
                  className="app_statstic"
                  id="counter"
                  data-aos="fade-in"
                  data-aos-duration="1500"
                >
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="17">
                          49
                        </span>
                        <span>Mil+</span>
                      </p>
                      <p>Rifas</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="2300">
                          32
                        </span>
                        <span>Mil+</span>
                      </p>
                      <p>Premiados</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="150">
                          17
                        </span>
                        <span>Mil+</span>
                      </p>
                      <p>Usuários</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-duration="1500">
                    <div className="text">
                      <p>
                        <span className="counter-value" data-count="08">
                          4
                        </span>
                        <span>M+</span>
                      </p>
                      <p>Bilhetes Gerenciados</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="abtImg text-center"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/appscreen.png" alt="imagem do app" />
                </div>
              </div>
              <div className="col-lg-4">
                <p data-aos="fade-up" data-aos-duration="1500">
                  A <b>Bilhete.app</b> é a plataforma ideal para criar,
                  personalizar e gerenciar rifas online. Oferecemos uma solução
                  prática, intuitiva e segura, com recursos que permitem receber
                  pagamentos via Pix, acompanhar vendas em tempo real e atrair
                  mais participantes.
                </p>
                <div
                  className="video_block"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img
                    className="thumbnil"
                    src="images/applicationvideothumb.png"
                    alt="imagem do vídeo"
                  />
                  <div className="playBtn">
                    <a
                      href="#"
                      className="popup-youtube play-button play_icon"
                      data-url="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&amp;mute=1"
                      data-toggle="modal"
                      data-target="#myModal"
                      title="XJj2PbenIsU"
                    >
                      <img src="fonts/play_white.svg" alt="ícone de play" />
                    </a>
                    <img
                      className="spin_text"
                      src="images/playvideotext.png"
                      alt="texto do vídeo"
                    />
                  </div>
                </div>
                <div
                  className="btn_block"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <a
                    onClick={() => openStoreApp()}
                    className="btn puprple_btn ml-0"
                  >
                    BAIXAR AGORA
                  </a>
                  <div className="btn_bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*   <!-- Text List flow Section Start --> */}
        <div
          className="text_list_section row_am"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <span className="title_badge down_fix">
              Por que escolher nosso app
            </span>
          </div>
          <div className="slider_block">
            <ReactOwlCarousel
              id="frmae_slider"
              className="owl-carousel owl-theme"
              loop
              margin={10}
              nav={false}
              items={3}
              autoplay
              autoplayTimeout={3000}
              autoplayHoverPause
            >
              <div className="item">
                <div className="text_block">
                  <span>Criação Fácil</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Gestão Prática</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Interface Intuitiva</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Pagamentos Rápidos</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Personalização Total</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Dados Seguros</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Suporte Ágil</span>
                  <span className="mark_star">•</span>
                </div>
              </div>
              <div className="item">
                <div className="text_block">
                  <span>Resultados Visíveis</span>
                  <span className="mark_star">•</span>
                </div>
              </div>{" "}
            </ReactOwlCarousel>
          </div>
        </div>

        {/*  <!-- Text List flow Section End -->

    <!-- Service Section Start --> */}
        <section className="row_am service_section">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <span className="title_badge mb-1">Serviços</span>
              <h2>
                Serviços <span>premium</span> do <br />
                nosso aplicativo, e de <span>graça!</span>
              </h2>
            </div>
            <div className="row service_blocks">
              <div className="col-md-6">
                <div
                  className="service_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="service_badge">
                    <i className="icofont-tasks-alt"></i>
                    <span>Criação de Rifas</span>
                  </div>
                  <h2>
                    <span>Crie rifas</span> personalizadas <br />
                    em poucos minutos
                  </h2>
                  <p>
                    Com a Bilhete.app, você pode criar rifas incríveis e
                    totalmente personalizadas de forma rápida e intuitiva.
                  </p>
                  <ul className="listing_block">
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-ui-check"></i>
                        </span>
                      </div>
                      <div className="text">
                        <h3>Fácil de usar</h3>
                        <p>Criação simples e prática diretamente pelo app.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-ui-check"></i>
                        </span>
                      </div>
                      <div className="text">
                        <h3>Configurações completas</h3>
                        <p>Adicione logotipos, cores e prêmios exclusivos.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/service1.png" alt="Serviço de Criação" />
                </div>
              </div>
            </div>
            <div className="row service_blocks flex-row-reverse">
              <div className="col-md-6">
                <div
                  className="service_text right_side"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="service_badge">
                    <i className="icofont-ui-clock"></i>
                    <span>Gestão de Rifas</span>
                  </div>
                  <h2>
                    <span>Gerencie suas rifas</span> <br />
                    com controle total
                  </h2>
                  <p>
                    Acompanhe a venda de bilhetes, visualize os participantes e
                    tenha controle total sobre sua rifa em tempo real.
                  </p>
                  <ul className="feature_list">
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Acompanhamento em tempo real.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Relatórios detalhados de vendas e participantes.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/service2.png" alt="Gestão de Rifas" />
                </div>
              </div>
            </div>
            <div className="row service_blocks">
              <div className="col-md-6">
                <div
                  className="service_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="service_badge">
                    <i className="icofont-list"></i>{" "}
                    <span>Pagamentos e Pix</span>
                  </div>
                  <h2>
                    <span>Receba pagamentos</span> <br />
                    diretamente no app
                  </h2>
                  <p>
                    Ofereça praticidade aos participantes com pagamentos rápidos
                    e seguros via Pix diretamente pelo app.
                  </p>
                  <ul className="feature_list">
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Pagamentos instantâneos e seguros.</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span>
                          <i className="icofont-check-circled"></i>
                        </span>
                      </div>
                      <div className="text">
                        <p>Saques fáceis direto para sua conta.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/service3.png" alt="Pagamentos e Pix" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Service Section End -->

    <!-- How It Work Section Start --> */}
        <section className="how_it_section white_text" id="howworks">
          <div
            className="how_it_inner"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <div className="dotes_blue">
              <img src="images/blue_dotes.png" alt="imagem" />
            </div>
            <div className="container">
              <div
                className="section_title"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <span className="title_badge">Rápido e Fácil</span>
                <h2>Como funciona em 3 passos!</h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div
                    className="steps_block step_border"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="steps">
                      <div className="icon">
                        <img src="images/howstep1.png" alt="imagem" />
                      </div>
                      <div className="text">
                        <h3>Crie sua Rifa</h3>

                        <p>
                          Comece agora! Crie uma rifa online em menos de <br />3
                          minutos com personalização completa.
                        </p>
                      </div>
                    </div>
                    <span className="step">01</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="steps_block step_border"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="steps">
                      <div className="icon">
                        <img src="images/howstep2.png" alt="imagem" />
                      </div>
                      <div className="text">
                        <h3>Compartilhe o Link</h3>
                        <p>
                          Compartilhe o link da sua rifa nas redes sociais,{" "}
                          <br />
                          WhatsApp ou onde preferir.
                        </p>
                      </div>
                    </div>
                    <span className="step">02</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="steps_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="steps">
                      <div className="icon">
                        <img src="images/howstep3.png" alt="imagem" />
                      </div>
                      <div className="text">
                        <h3>Arrecade</h3>
                        <p>
                          Acompanhe suas vendas em tempo real e receba <br />
                          pagamentos diretamente no Pix.
                        </p>
                      </div>
                    </div>
                    <span className="step">03</span>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="btn_block">
                  <a
                    onClick={() => openStoreApp()}
                    className="btn puprple_btn ml-0"
                  >
                    Baixar Aplicativo
                  </a>
                  <div className="btn_bottom"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="review_section row_am" id="reviews">
        <div className="container">
          <div className="positive_inner">
            <div className="row">
              <div className="col-md-6 sticky-top">
                <div
                  className="sidebar_text"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="section_title text-left">
                    <span className="title_badge">Avaliações</span>
                    <h2>
                      <span>Avaliações positivas </span> <br />
                      dos nossos usuários
                    </h2>
                  </div>
                  <div className="google_rating">
                    <div className="star">
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                      <span>
                        <i className="icofont-star"></i>
                      </span>
                    </div>
                    <p>4.8/5.0 Avaliação</p>
                  </div>
                  <div className="user_review">
                    <p>
                      1500+{" "}
                      <a onClick={() => openStoreApp()}>
                        Avaliações de usuários{" "}
                        <i className="icofont-arrow-right"></i>
                      </a>
                    </p>
                  </div>
                  <div className="smiley_icon">
                    <img src="images/smily.png" alt="Imagem de sorriso" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="review_side">
                  <div
                    className="review_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="coustomer_info">
                      <div className="avtar">
                        <img src="images/review1.png" alt="Imagem do usuário" />
                        <div className="text">
                          <h3>João</h3>
                          <span>Empreendedor Digital</span>
                        </div>
                      </div>
                      <div className="star">
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      "A Bilhete.app revolucionou minha forma de organizar
                      rifas! Com uma interface intuitiva e recursos completos,
                      consigo criar, gerenciar e acompanhar tudo com facilidade.
                      É rápido, seguro e extremamente eficiente. Recomendo para
                      todos que desejam profissionalizar suas rifas!"
                    </p>
                  </div>

                  <div
                    className="review_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="coustomer_info">
                      <div className="avtar">
                        <img
                          src="images/review2..png"
                          alt="Imagem do usuário"
                        />
                        <div className="text">
                          <h3>Lucas</h3>
                          <span>Organizador de Eventos</span>
                        </div>
                      </div>
                      <div className="star">
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      Criar minha rifa com a Bilhete.app foi uma experiência
                      incrível! O sistema é extremamente intuitivo, facilitando
                      cada etapa do processo. Além disso, os pagamentos via Pix
                      são rápidos e funcionam de forma perfeita, trazendo
                      segurança e praticidade. Recomendo para todos que buscam
                      simplicidade e eficiência!
                    </p>
                  </div>
                  <div
                    className="review_block"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div className="coustomer_info">
                      <div className="avtar">
                        <img
                          src="images/review3..png"
                          alt="Imagem do usuário"
                        />
                        <div className="text">
                          <h3>Maria </h3>
                          <span>Pequeno Empreendedor</span>
                        </div>
                      </div>
                      <div className="star">
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                        <span>
                          <i className="icofont-star"></i>
                        </span>
                      </div>
                    </div>
                    <p>
                      A melhor solução para rifas online! Controle total, design
                      profissional e suporte excelente. Parabéns à equipe!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page_wrapper">
        <section className="row_am our_client">
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <span className="title_badge">Na mídia</span>
              <h2>
                <span className="d-block">A bilhete já ajudou,</span>
                e aqueceu vários corações!
                <br />
                <br />
                💙
              </h2>
            </div>
            <ul className="client_list">
              <li
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  openLink(
                    "https://g1.globo.com/df/distrito-federal/noticia/2022/12/06/palhaco-rifa-album-dourado-da-copa-do-mundo-para-fazer-festa-de-natal-para-criancas-no-df.ghtml"
                  )
                }
              >
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/g1_3.png" alt="image" />
                </div>
              </li>
              <li
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  openLink(
                    "https://vejasp.abril.com.br/coluna/arnaldo-lorencato/helena-rizzo-rifa-quebrada-alimentada"
                  )
                }
              >
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/veja.png" alt="image" />
                </div>
              </li>
              <li
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  openLink(
                    "https://www.opovo.com.br/noticias/ceara/2022/12/02/familia-cria-rifa-online-para-custear-transplante-de-filho-com-cancer.html"
                  )
                }
              >
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/opovo.png" alt="image" />
                </div>
              </li>
              <li
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  openLink(
                    "https://istoe.com.br/familia-de-marilia-mendonca-rifa-violao-da-artista-e-doara-valor-as-familias-afetadas-pela-chuva-no-rs/"
                  )
                }
              >
                <div
                  className="client_logo"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <img src="images/gente.png" alt="image" />
                </div>
              </li>
            </ul>
          </div>
        </section>

        {/* <section
          className="row_am pricing_section white_text"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="pricing_inner">
            <div className="container">
              <div className="dotes_blue">
                <img src="images/blue_dotes.png" alt="image" />
              </div>
              <div
                className="section_title"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="300"
              >
                <span className="title_badge">Pricing</span>
                <h2>Choose your plan</h2>
              </div>

              <ul
                className="nav nav-tabs"
                id="myTab"
                role="tablist"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="monthly-tab"
                    data-toggle="tab"
                    data-target="#monthly"
                    type="button"
                    role="tab"
                    aria-controls="monthly"
                    aria-selected="true"
                  >
                    Monthly
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="yearly-tab"
                    data-toggle="tab"
                    data-target="#yearly"
                    type="button"
                    role="tab"
                    aria-controls="yearly"
                    aria-selected="false"
                  >
                    Yearly
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="monthly"
                  role="tabpanel"
                  aria-labelledby="monthly-tab"
                >
                  <div className="pricing_pannel">
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className="pannel_block"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <div className="heading">
                            <h3>Free</h3>
                            <span>For single users</span>
                          </div>
                          <div className="pricing">
                            <h3>
                              $15 <span>/month</span>
                            </h3>
                          </div>
                          <ul className="features">
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Up to 15 GB cloud storage</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>30 day chat history</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>Data security</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>5 People access</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>24/7 Support</p>
                            </li>
                          </ul>
                          <div className="btn_block">
                            <a
                              href="contact.html"
                              className="btn puprple_btn ml-0"
                            >
                              Choose Plan
                            </a>
                            <div className="btn_bottom"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="pannel_block highlited_block"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <div className="heading">
                            <h3>Premium</h3>
                            <span>For agencies</span>
                            <span className="offer">Save 50%</span>
                          </div>
                          <div className="pricing">
                            <h3>
                              $45 <span>/month</span>
                            </h3>
                          </div>
                          <ul className="features">
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Up to 15 GB cloud storage</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>30 day chat history</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Data security</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>5 People access</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>24/7 Support</p>
                            </li>
                          </ul>
                          <div className="btn_block white_btn">
                            <a
                              href="contact.html"
                              className="btn puprple_btn ml-0"
                            >
                              Choose Plan
                            </a>
                            <div className="btn_bottom"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div
                          className="pannel_block"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <div className="heading">
                            <h3>Standard</h3>
                            <span>For team</span>
                          </div>
                          <div className="pricing">
                            <h3>
                              $30 <span>/month</span>
                            </h3>
                          </div>
                          <ul className="features">
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Up to 15 GB cloud storage</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>30 day chat history</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-check-circled"></i>
                              </span>
                              <p>Data security</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>5 People access</p>
                            </li>
                            <li>
                              <span className="icon">
                                <i className="icofont-close-circled"></i>
                              </span>
                              <p>24/7 Support</p>
                            </li>
                          </ul>
                          <div className="btn_block">
                            <a
                              href="contact.html"
                              className="btn puprple_btn ml-0"
                            >
                              Choose Plan
                            </a>
                            <div className="btn_bottom"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="yearly"
                  role="tabpanel"
                  aria-labelledby="yearly-tab"
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="pannel_block">
                        <div className="heading">
                          <h3>Free</h3>
                          <span>For single users</span>
                        </div>
                        <div className="pricing">
                          <h3>
                            $99 <span>/month</span>
                          </h3>
                        </div>
                        <ul className="features">
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Up to 15 GB cloud storage</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>30 day chat history</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>Data security</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>5 People access</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>24/7 Support</p>
                          </li>
                        </ul>
                        <a href="contact.html" className="btn puprple_btn ml-0">
                          Choose Plan
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pannel_block highlited_block">
                        <div className="heading">
                          <h3>Premium</h3>
                          <span>For agencies</span>
                          <span className="offer">Save 50%</span>
                        </div>
                        <div className="pricing">
                          <h3>
                            $399 <span>/month</span>
                          </h3>
                        </div>
                        <ul className="features">
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Up to 15 GB cloud storage</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>30 day chat history</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Data security</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>5 People access</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>24/7 Support</p>
                          </li>
                        </ul>
                        <div className="btn_block white_btn">
                          <a
                            href="contact.html"
                            className="btn puprple_btn ml-0"
                          >
                            Choose Plan
                          </a>
                          <div className="btn_bottom"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pannel_block">
                        <div className="heading">
                          <h3>Standard</h3>
                          <span>For team</span>
                        </div>
                        <div className="pricing">
                          <h3>
                            $299 <span>/month</span>
                          </h3>
                        </div>
                        <ul className="features">
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Up to 15 GB cloud storage</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>30 day chat history</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-check-circled"></i>
                            </span>
                            <p>Data security</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>5 People access</p>
                          </li>
                          <li>
                            <span className="icon">
                              <i className="icofont-close-circled"></i>
                            </span>
                            <p>24/7 Support</p>
                          </li>
                        </ul>
                        <a href="contact.html" className="btn puprple_btn ml-0">
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  className="contact_text text-center"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  Not sure what to choose ? <a href="#">contact us</a> for
                  custom packages
                </p>
              </div>
            </div>
          </div>
        </section> */}

        {/*  <section className="row_am interface_section">
          <div className="container-fluid">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="300"
            >
              <span className="title_badge">Aplicativo</span>
              <h2>
                Interface <span>fácil</span> !
              </h2>
            </div>

            <div
              className="screen_slider"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div id="screen_slider" className="owl-carousel owl-theme">
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn1.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn2.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn3.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn4.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn5.png" alt="image" />
                  </div>
                </div>
                <div className="item">
                  <div className="screen_frame_img">
                    <img src="images/intrscrn2.png" alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <div
          className="text_list_section row_am downaload_section"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="yellow_dotes">
              <img src="images/yellow_dotes.png" alt="image" />
            </div>
            <div className="center_screen">
              <div className="img">
                <img src="images/downloadScreen.png" alt="image" />
              </div>

              <ul className="app_btn">
                <li>
                  <a
                    style={{
                      padding: 0,
                    }}
                    href="https://play.google.com/store/apps/details?id=app.bilhete&fbclid=fbclid"
                  >
                    <img
                      className="blue_img"
                      src="images/googleplay.png"
                      alt="image"
                    />
                  </a>
                </li>
                <li>
                  <a
                    style={{
                      padding: 0,
                    }}
                    href="https://play.google.com/store/apps/details?id=app.bilhete&fbclid=fbclid"
                  >
                    <img
                      className="blue_img"
                      src="images/appstorebtn.png"
                      alt="image"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="background_slider">
            <div className="dowanload_slider">
              <div className="downlist">
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
            </div>
            <div className="slider_block">
              <div
                className="owl-carousel owl-theme"
                id="text_list_flow_download"
              >
                <div className="item">
                  <div className="text_block">
                    <span>Baixar App </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Baixar App </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Baixar App </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Baixar App </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Baixar App </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
                <div className="item">
                  <div className="text_block">
                    <span>Baixar App </span>
                    <span className="mark_star">•</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dowanload_slider">
              <div className="downlist">
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
                <div className="text_block">
                  <span>Baixar App </span>
                  <span className="mark_star">•</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer
          className="white_text"
          data-aos="fade-in"
          data-aos-duration="1500"
          id="footer"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="logo_side">
                  <div className="logo">
                    <a href="#">
                      <img
                        src="images/logo_footer.png"
                        width="200"
                        alt="Logo Bilhete.app"
                      />
                    </a>
                  </div>
                  <div className="news_letter">
                    <h3>Inscreva-se na nossa newsletter</h3>
                    <p>Receba todas as novidades diretamente no seu e-mail.</p>
                    <form>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Digite seu e-mail"
                        />
                        <button className="btn">
                          <i className="icofont-paper-plane"></i>
                        </button>
                      </div>
                      <p className="note">
                        Ao clicar em enviar, você concorda em receber nossas
                        mensagens.
                      </p>
                    </form>
                  </div>
                  <ul className="contact_info">
                    <li>
                      <a href="mailto:contato@bilhete.app">
                        contato@bilhete.app
                      </a>
                    </li>
                    <li>
                      <a href="tel:+5511917742435">+55 11 91774-2435</a>
                    </li>
                  </ul>
                  <ul className="social_media">
                    <li>
                      <a
                        href="https://api.whatsapp.com/send/?phone=5511917742435"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-whatsapp"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/bilhete.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="download_side">
                  <h3>Baixe o app</h3>
                  <ul className="app_btn">
                    <li>
                      <a
                        style={{
                          padding: 0,
                        }}
                        href="#"
                      >
                        <img
                          className="blue_img"
                          src="images/googleplay.png"
                          alt="Baixe na Google Play"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        style={{
                          padding: 0,
                        }}
                        href="#"
                      >
                        <img
                          className="blue_img"
                          src="images/appstorebtn.png"
                          alt="Baixe na App Store"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_bottom">
            <div className="container">
              <div className="ft_inner">
                <div className="copy_text">
                  <p>© Direitos reservados {currentYear}.</p>
                </div>
                <ul className="links">
                  <li>
                    <a href="#home">Início</a>
                  </li>
                  <li>
                    <a href="#about">Sobre Nós</a>
                  </li>
                  <li>
                    <a href="#howworks">Como Funciona</a>
                  </li>
                  <li>
                    <a href="#reviews">Avaliações</a>
                  </li>
                  <li>
                    <a href="#footer">Contato</a>
                  </li>
                </ul>
                <div className="design_by">
                  <p>
                    Criado por{" "}
                    <a href="#home" target="_blank" rel="noopener noreferrer">
                      Bilhete.app
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div className="go_top" id="Gotop">
          <span>
            <i className="icofont-arrow-up"></i>
          </span>
        </div>

        <div
          className="modal fade youtube-video"
          id="myModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
              >
                <i className="icofont-close-line-circled"></i>
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width="640"
                    height="360"
                    allowFullScreen=""
                  ></iframe>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
