import { Helmet } from "react-helmet";
import React from "react";

const Seo = ({ title, description, url, keywords, image }) => {
  return (
    <Helmet
      htmlAttributes={{ lang: "pt-BR" }}
      title={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "keywords",
          content: keywords,
        },
        {
          name: "image",
          content: image,
        },
        {
          name: "og:title",
          content: title,
        },
        {
          name: "og:url",
          content: url,
        },
        {
          name: "og:image",
          content: image,
        },

        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: description,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:image",
          content: image,
        },
      ]}
      links={[
        {
          rel: "canonical",
          href: url,
        },
      ]}
    />
  );
};
export default Seo;
