import axios from "axios";

const url = "https://bilhete-app-api.onrender.com/v2/tickets/";
const urlCheckout = "https://bilhete-app-api.onrender.com/checkout";

export async function getRifaFromAPI(username, id) {
  try {
    const response = await axios.get(`${url}${username}/${id}`);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}

export async function postCheckout(data) {
  try {
    const response = await axios.post(`${urlCheckout}`, data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}

export async function putCheckout(data) {
  try {
    const response = await axios.put(`${urlCheckout}/${data.id}`, data);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
}

export const getTicketTopBuys = async (id) => {
  try {
    const response = await axios.get(`${urlCheckout}/top/${id}`);
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : error.message;
  }
};
