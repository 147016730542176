import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CircularProgress, Grid, Typography } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import StepOne from "./steps/StepOne";
import { postCheckout, putCheckout } from "../../hooks/RifaPage/useRifaPage";
import StepTwo from "./steps/StepOne/StepTwo";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ArrowRight, KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CheckoutModal({
  open,
  handleClose,
  formData,
  handleChange,
  selectedNumbers,
}) {
  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      uuid: "",
      id: "",
      custumer_name: null,
      custumer_email: null,
      custumer_phone: null,
      custumer_phone_repeat: null,
      selectedNumbers: [],
    },
    validationSchema: Yup.object({
      custumer_name: Yup.string().required("Campo obrigatório"),
      custumer_email: Yup.string()
        .email("E-mail inválido")
        .required("Campo obrigatório"),
      custumer_phone: Yup.string().required("Campo obrigatório"),
      custumer_phone_repeat: Yup.string().oneOf(
        [Yup.ref("custumer_phone"), null],
        "Telefones não são iguais!"
      ),
    }),
    onSubmit: (values) => updateCheckout(values),
  });

  React.useEffect(() => {
    formik.setFieldValue("selectedNumbers", selectedNumbers);
  }, [selectedNumbers]);

  const createCheckout = () => {
    if (step == 1) {
      setLoading(true);
      postCheckout(formData)
        .then((res) => {
          formik.setFieldValue("uuid", res?.checkout?.id);
          formik.setFieldValue("id", res?.checkout?.id);
          formik.setFieldValue("custumer_phone", res?.checkout?.custumer_phone);
        })
        .finally(() => {
          setLoading(false);
          setStep(2);
        });
    }
    if (step == 2) {
      formik.submitForm();
    }
  };

  const updateCheckout = (values) => {
    setLoading(true);
    putCheckout(values)
      .then((res) => {
        console.log(res);
        navigate("/checkout/" + formik.values.uuid);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      className="modalEnd"
      PaperProps={{
        sx: {
          width: "100%",
          p: 0,
          m: 0,
        },
      }}
    >
      <DialogTitle>{"Preencha as informações."}</DialogTitle>
      <DialogContent>
        {/* {JSON.stringify(formData)} */}
        <DialogContentText id="alert-dialog-slide-description">
          {loading ? (
            <Grid container direction="row" justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {step == 1 && (
                <StepOne handleChange={handleChange} formData={formData} />
              )}
              {step == 2 && (
                <StepTwo
                  handleChange={handleChange}
                  formData={formData}
                  formik={formik}
                  setStep={setStep}
                />
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          sx={{
            pl: 2,
            pr: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12}>
            <Button
              disabled={
                formData?.custumer_phone?.length <= 16 ||
                !formData?.custumer_phone?.length
              }
              onClick={() => createCheckout()}
              variant="contained"
              size="large"
              endIcon={<KeyboardArrowRight />}
              fullWidth
            >
              <span className="MuiButton-label-primary">
                {" "}
                {step == 1 ? "Continuar" : "Pagar Bilhete"}
              </span>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
