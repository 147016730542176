import { CopyAll } from "@mui/icons-material";
import { IoAlertCircleOutline } from "react-icons/io5";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import QRCodeStyling from "qr-code-styling";
import * as React from "react";
import toast from "react-hot-toast";
import { FaPix } from "react-icons/fa6";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import logoBilhete from "../../assets/imgs/pix.jpg";
import { Button } from "../../components/Button";
import { Card as CardCustom } from "../../components/Card";
import { CardHeader } from "../../components/CardHeader";
import CountdownTimer from "../../components/CountdownTimer";
import { Footer } from "../../components/Footer";
import HeaderMenu from "../../components/HeaderMenu";
import CheckerStatus from "../../components/StatusPayment";
import { Typography } from "../../components/Typography";
import { getCheckoutFromAPI } from "../../hooks/Checkout/useCheckout";

const FadeComponent = () => {
  const [showFirstMessage, setShowFirstMessage] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setShowFirstMessage((prev) => !prev);
    }, 3000); // Troca a cada 3 segundos
    return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
  }, []);

  return (
    <div>
      <CSSTransition
        in={showFirstMessage}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <Typography
          style={{
            position: "absolute",
          }}
        >
          Selecionando números
        </Typography>
      </CSSTransition>
      <CSSTransition
        in={!showFirstMessage}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <Typography
          style={{
            position: "absolute",
          }}
        >
          Aguarde
        </Typography>
      </CSSTransition>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const qrCode = new QRCodeStyling({
  width: 350,
  height: 350,
  margin: 10,
  image: "",
  dotsOptions: {
    color: "black",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10,
  },
  backgroundOptions: {
    color: "yellow",
  },
  cornersSquareOptions: {
    color: "black",
  },
});

export default function Checkout() {
  const { uuid } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [payment, setPayment] = React.useState(false);
  const [dataCheckout, setDataCheckout] = React.useState({});
  const [dataTicket, setDataTicket] = React.useState({});
  const [reserved, setReserved] = React.useState([]);
  const theme = useTheme();

  const getCheckout = () => {
    getCheckoutFromAPI(uuid)
      .then((res) => {
        setDataCheckout(res.data);
        setDataTicket(res.ticket);
        setReserved(res.reserved);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getCheckout();
  }, [uuid]);

  function QrCodeComponent(props) {
    const ref = React.useRef(null);

    React.useEffect(() => {
      const options = qrCode._options;
      options.cornersSquareOptions.color = props.eyeColor;
      options.image = props.centerImageSrc;
      options.backgroundOptions.color = props.bgColor;
      options.dotsOptions.color = props.fgColor;
      qrCode.append(ref.current);
    }, []);

    React.useEffect(() => {
      qrCode.update({
        data: props.data,
      });
    }, [props.data]);

    return (
      <div
        style={{
          backgroundColor: props.bgColor && props.bgColor,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            borderRadius: "7px",
          }}
          ref={ref}
        ></div>
      </div>
    );
  }

  const copiarParaAreaDeTransferencia = (texto) => {
    // Cria um elemento de texto temporário
    const elemento = document.createElement("textarea");
    // Define o valor do elemento com o texto passado como parâmetro
    elemento.value = texto;
    // Adiciona o elemento ao documento
    document.body.appendChild(elemento);
    // Seleciona o texto
    elemento.select();
    // Executa o comando de cópia
    document.execCommand("copy");
    // Remove o elemento do documento
    document.body.removeChild(elemento);
    toast.success("Código pix copiado!", {
      position: "bottom-center",
    });
  };

  const onPayment = () => {
    setPayment(true);
    getCheckout();
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <HeaderMenu />
      <Grid item xs={12} sm={8} md={5} sx={{ p: 2 }}>
        <Grid container direction="row" spacing={1}>
          <CheckerStatus
            uuid={uuid}
            data={dataCheckout}
            onPayment={onPayment}
          />

          <Grid item xs="12">
            <Card
              variant="outlined"
              elevation={20}
              sx={{
                p: 0,
              }}
            >
              <Grid
                item
                xs="12"
                sx={{
                  mb: 0,
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    border: "2px dashed ",
                    borderRadius: 1,
                    borderColor: "divider",
                    position: "relative",
                    p: 1,
                    bgcolor: "background.paper",
                    "& svg": {
                      m: 0,
                    },
                    "& hr": {
                      mx: 0.5,
                    },
                  }}
                >
                  <Grid item xs="6">
                    <Grid
                      item
                      xs="12"
                      sx={{
                        mb: -1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 900,
                          fontSize: 18,
                        }}
                      >
                        Valor
                      </Typography>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: 900,
                            fontSize: 12,
                            pt: 1,
                            mr: 0.7,
                          }}
                        >
                          R$
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: 900,
                            fontSize: 30,
                          }}
                        >
                          <NumericFormat
                            value={Number(dataCheckout?.value)}
                            decimalScale={2}
                            decimalSeparator=","
                            displayType="text"
                            fixedDecimalScale
                            thousandSeparator="."
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider orientation="vertical" flexItem />
                  <Grid
                    item
                    xs="6"
                    sx={{
                      pl: 1,
                    }}
                  >
                    <Grid
                      item
                      xs="12"
                      sx={{
                        mb: -1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 900,
                          fontSize: 18,
                        }}
                      >
                        {payment ? "Status" : "Expira em"}
                      </Typography>
                    </Grid>
                    <Grid item xs="12">
                      <Grid container direction="row">
                        {!payment ? (
                          <Grid item>
                            {!loading && (
                              <CountdownTimer
                                datestamp={dataCheckout?.date}
                                reserved_tickts={dataCheckout?.reserved_tickets}
                              />
                            )}
                          </Grid>
                        ) : (
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 900,
                                fontSize: 25,
                                color: "green",
                              }}
                            >
                              PAGO
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs="12">
            <CardCustom
              variant="outlined"
              elevation={20}
              sx={{
                p: 0,
              }}
            >
              <CardHeader
                title={`Ação${reserved.length >= 1 && ` Reservada`}`}
              />
              <CardContent sx={{ mt: -2 }}>
                <Grid container display="flex" flexWrap="nowrap" gap={2}>
                  <Grid item>
                    <div
                      style={{
                        width: 80,
                        height: 80,
                        backgroundImage: `url("${dataTicket.img1}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center", // opcional, ajuda a centralizar a imagem
                        backgroundRepeat: "no-repeat",
                        borderRadius: 8,
                        border: "1px solid " + theme.palette.primary.main,
                      }}
                    ></div>
                  </Grid>
                  <Grid item>
                    <Typography
                      color="primary"
                      variant="h6"
                      style={{
                        lineHeight: 1.1,
                        fontWeight: 600,
                      }}
                    >
                      {dataTicket?.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        lineHeight: 1.1,
                        fontWeight: 400,
                        fontSize: 17,
                      }}
                    >
                      Bilhetes:{" "}
                      <NumericFormat
                        value={dataTicket?.initial_total_tickets}
                        decimalScale={2}
                        decimalSeparator=","
                        displayType="text"
                        thousandSeparator="."
                      />
                    </Typography>

                    <Typography
                      variant="h6"
                      style={{
                        lineHeight: 1.1,
                        fontWeight: 400,
                        fontSize: 17,
                      }}
                    >
                      Preço do bilhete: R${" "}
                      <NumericFormat
                        value={dataTicket?.price}
                        decimalScale={2}
                        decimalSeparator=","
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator="."
                      />
                    </Typography>

                    <Typography
                      variant="h6"
                      style={{
                        lineHeight: 1.1,
                        fontWeight: 400,
                        fontSize: 17,
                      }}
                    >
                      Quantidade:{" "}
                      <NumericFormat
                        value={dataCheckout?.amount}
                        decimalScale={2}
                        decimalSeparator=","
                        displayType="text"
                        thousandSeparator="."
                      />
                    </Typography>
                  </Grid>
                </Grid>
                {!payment && (
                  <>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        border: "2px dashed ",
                        borderRadius: 3,
                        borderColor: "divider",
                        p: 1,
                        pt: 0,
                        pb: 0,
                        mb: 1,
                        mt: 1,
                      }}
                    >
                      <Grid item xs={2}>
                        <IoAlertCircleOutline
                          style={{
                            opacity: 0.5,
                            fontSize: 30,
                          }}
                          color="red"
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          mt: 1,
                          mb: 1,
                        }}
                        xs={10}
                      >
                        {dataCheckout.reserved_tickets == 1 ? (
                          <Typography>
                            Seu{dataCheckout?.amount > 1 && `s`}{" "}
                            {dataCheckout?.amount} Bilhete
                            {dataCheckout?.amount > 1 && `s`} ser
                            {dataCheckout?.amount > 1 ? `ão` : `á`} reservado
                            {dataCheckout?.amount > 1 && `s`} pelo nosso sistema
                            por 15 minutos!
                          </Typography>
                        ) : (
                          <Typography>
                            Seu{dataCheckout?.amount > 1 && `s`}{" "}
                            {dataCheckout?.amount} Bilhete
                            {dataCheckout?.amount > 1 && `s`} ser
                            {dataCheckout?.amount > 1 ? `ão` : `á`} selecionado
                            {dataCheckout?.amount > 1 && `s`} pelo nosso sistema
                            assim que o pagamento for confirmado!
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    {reserved && reserved.length >= 1 && (
                      <Grid
                        container
                        direction="row"
                        display="flex"
                        style={{
                          maxHeight: 300,
                          overflowY: "auto",
                        }}
                        gap={1}
                      >
                        {reserved?.map((item) => (
                          <Grid
                            item
                            sx={{
                              border: `1px solid ${theme.palette.primary.main}`,
                              borderRadius: 50,
                              paddingLeft: 1,
                              paddingRight: 1,
                              paddingTop: 1,
                              paddingBottom: 1,
                              color: theme.palette.primary.main,
                              fontWeight: 900,
                            }}
                          >
                            <NumericFormat
                              value={item}
                              decimalScale={2}
                              decimalSeparator=","
                              displayType="text"
                              thousandSeparator="."
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                )}
              </CardContent>
            </CardCustom>
          </Grid>
          {payment ? (
            <Grid item xs="12">
              {loading ? (
                <Card
                  variant="outlined"
                  elevation={20}
                  sx={{
                    p: 0,
                  }}
                >
                  <CardContent sx={{}}>
                    <Grid container direction="row" justifyContent="center">
                      <FadeComponent />
                    </Grid>
                    <Grid container direction="row" justifyContent="center">
                      <CircularProgress />
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                <Grid container direction="row" gap={1}>
                  <Grid item xs="12">
                    <CardCustom variant="outlined" elevation={20}>
                      <CardHeader title="Seus Bilhetes" />
                      <CardContent sx={{ mt: -2 }}>
                        <Grid container direction="row" justifyContent="center">
                          <Grid item xs={12} className="boxResume">
                            <Grid
                              item
                              xs={12}
                              sx={{
                                position: "relative",
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                display="flex"
                                style={{
                                  maxHeight: 300,
                                  overflowY: "auto",
                                  paddingBottom: 50,
                                }}
                                gap={1}
                              >
                                {dataCheckout?.sold_numbers?.map((item) => (
                                  <Grid
                                    item
                                    sx={{
                                      border: `1px solid ${theme.palette.primary.main}`,
                                      borderRadius: 50,
                                      paddingLeft: 1,
                                      paddingRight: 1,
                                      paddingTop: 1,
                                      paddingBottom: 1,
                                      color: theme.palette.primary.main,
                                      fontWeight: 900,
                                    }}
                                  >
                                    <NumericFormat
                                      value={item}
                                      decimalScale={2}
                                      decimalSeparator=","
                                      displayType="text"
                                      thousandSeparator="."
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                              <div
                                style={{
                                  width: "100%",
                                  position: "absolute",
                                  height: "60px",
                                  bottom: 0,
                                  left: 0,
                                  background: "white",
                                  background:
                                    "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
                                }}
                              ></div>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container direction="row">
                          <Typography variant="h5">
                            {dataCheckout?.sold_numbers?.length || 0} Bilhetes
                          </Typography>
                        </Grid>
                      </CardContent>
                    </CardCustom>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item xs="12">
              <Card
                variant="outlined"
                elevation={20}
                sx={{
                  p: 0,
                }}
              >
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      border: "2px dashed ",
                      borderRadius: 1,
                      borderColor: "divider",
                      p: 1,
                      pt: 0,
                      pb: 0,
                      mb: 1,
                    }}
                  >
                    <Grid item xs={2}>
                      <FaPix
                        style={{
                          opacity: 0.3,
                          fontSize: 30,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        mt: 1,
                        mb: 1,
                      }}
                      xs={10}
                    >
                      <Typography>
                        Use o código do PIX para efetuar o pagamento!
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    spacing={1}
                    container
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item xs="12">
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        label="Código PIX"
                        variant="filled"
                        multiline
                        value={dataCheckout?.openpix_qrcode}
                      />
                    </Grid>
                    <Grid
                      item
                      xs="8"
                      sx={{
                        mt: -2,
                      }}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() =>
                          copiarParaAreaDeTransferencia(
                            dataCheckout?.openpix_qrcode
                          )
                        }
                        startIcon={<CopyAll />}
                      >
                        Copiar código PIX
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2 }} />
                  <Grid container direction="row" justifyContent="center">
                    <QrCodeComponent
                      data={dataCheckout?.openpix_qrcode}
                      bgColor="#FFFFFF"
                      fgColor="black"
                      eyeColor="#3f67f3"
                      titleColor="white"
                      centerImageSrc={logoBilhete}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs="12">
            <CardCustom
              variant="outlined"
              elevation={20}
              sx={{
                p: 0,
              }}
            >
              <CardHeader title="Dados da Compra" />
              <CardContent sx={{ mt: -2 }}>
                <Grid container direction="row" justifyContent="center">
                  <Grid item xs={12} className="boxResume">
                    <Grid item xs={12} className="boxResumeTitle">
                      Código da Compra:
                    </Grid>
                    <Grid item xs={12}>
                      {dataCheckout?.id}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="boxResume">
                    <Grid item xs={12} className="boxResumeTitle">
                      Nome:
                    </Grid>
                    <Grid item xs={12}>
                      {dataCheckout?.custumer_name}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="boxResume">
                    <Grid item xs={12} className="boxResumeTitle">
                      E-mail:
                    </Grid>
                    <Grid item xs={12} className="boxResume">
                      {dataCheckout?.custumer_email}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="boxResume">
                    <Grid item xs={12} className="boxResumeTitle">
                      Telefone:
                    </Grid>
                    <Grid item xs={12} className="boxResume">
                      {dataCheckout?.custumer_phone}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="boxResume">
                    <Grid item xs={12} className="boxResumeTitle">
                      Total:
                    </Grid>
                    <Grid item xs={12}>
                      <NumericFormat
                        value={Number(dataCheckout?.value)}
                        decimalScale={2}
                        decimalSeparator=","
                        prefix="R$ "
                        displayType="text"
                        fixedDecimalScale
                        thousandSeparator="."
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CardCustom>
          </Grid>
        </Grid>
        <Footer theme={theme} />
      </Grid>
    </Grid>
  );
}
