import { format, isBefore } from "date-fns";
import { ptBR } from "date-fns/locale";

export default function formatDateRelease(date = "", dateFormat) {
  const noDate = "[sem data]";
  const drawCompleted = "Sorteio realizado";

  if (!date) {
    return noDate;
  }

  try {
    // Ajusta a data caso não inclua hora
    const fixDate = date.search(":") > 0 ? date : `${date} 00:00`;

    const parsedDate = new Date(fixDate);

    // Verifica se a data já passou
    if (isBefore(parsedDate, new Date())) {
      return drawCompleted;
    }

    const defaultFormat = dateFormat || "d 'de' MMMM 'de' yyyy - HH'h'mm";

    return format(parsedDate, defaultFormat, {
      locale: ptBR,
    });
  } catch (_) {
    return noDate;
  }
}
