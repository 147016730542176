import React, { useEffect } from "react";

const SmoothScroll = () => {
  useEffect(() => {
    const handleAnchorClick = (event) => {
      const targetId = event.target.getAttribute("href");
      if (targetId && targetId.startsWith("#")) {
        event.preventDefault();

        // Se o ID for "home", sobe até o topo
        if (targetId === "#home") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const targetElement = document.querySelector(targetId);
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      }
    };

    // Adiciona o evento a todas as âncoras
    const anchors = document.querySelectorAll('a[href^="#"]');
    anchors.forEach((anchor) => {
      anchor.addEventListener("click", handleAnchorClick);
    });

    // Cleanup
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener("click", handleAnchorClick);
      });
    };
  }, []);

  return null; // Esse componente não renderiza nada
};

export default SmoothScroll;
