export function stripHTML(html) {
  // Cria um elemento temporário
  const tempElement = document.createElement("div");

  // Adiciona uma classe 'hidden' à div
  tempElement.classList.add("hidden");

  // Define o conteúdo HTML no elemento temporário
  tempElement.innerHTML = html;

  // Retorna apenas o texto, sem tags HTML
  return tempElement.textContent || tempElement.innerText || "";
}

export default stripHTML;
