import {
  Add,
  Adjust,
  Circle,
  Close,
  ConfirmationNumber,
  DateRange,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Remove,
  Star,
  StarBorder,
} from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DOMPurify from "dompurify";
import { useEffect, useRef, useState } from "react";
import ReactElasticCarousel from "react-elastic-carousel";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";
import "./App.css";
import noImgage from "./assets/imgs/noimage.png";
import { Button } from "./components/Button";
import { Card } from "./components/Card";
import { CardContent } from "./components/CardContent";
import { CardHeader } from "./components/CardHeader";
import { CardHeaderRanking } from "./components/CardHeaderRanking";
import CheckoutModal from "./components/CheckoutModal";
import { Footer } from "./components/Footer";
import HeaderMenu from "./components/HeaderMenu";
import { NetworksButton } from "./components/NetowroksButton";
import { TextFieldAmount } from "./components/TextFieldAmount";
import { Typography } from "./components/Typography";
import { getRifaFromAPI, getTicketTopBuys } from "./hooks/RifaPage/useRifaPage";
import formatDateRelease from "./utils/formatDateRelease";
import { getTypeTicket, IconDisplay } from "./utils/getTypeTicket";

import trofeuOuro from "./assets/imgs/trofeu-estrela.png";
import trofeuBron from "./assets/imgs/trofeu-estrela_bron.png";
import trofeuPrata from "./assets/imgs/trofeu-estrela_silver.png";

import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import Seo from "./components/Seo";
import stripHTML from "./utils/stripHTML";
import { FaLink } from "react-icons/fa6";
import BuyTicketContent from "./components/BuyTicketContent";
import NumberSelector from "./components/NumberSelector";

const shareUrl = window.location.href;

const useStyles = makeStyles({
  visible: {
    "&:hover": {
      color: "#27b279",
    },
  },
  arrowsBoxLeft: {
    position: "absolute",
    height: "100%",
    zIndex: 1,
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
      zIndex: 1,
    },
  },
  arrowsBoxRight: {
    position: "absolute",
    height: "100%",
    zIndex: 1,
    right: 0,
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
      zIndex: 1,
    },
  },
  arrowsLeft: {
    position: "relative",
    zIndex: 1,
    color: "#FFFFFF !important",
    top: "40%",
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
    },
  },
  arrowsRight: {
    position: "relative",
    zIndex: 1,
    color: "#FFFFFF !important",
    marginLeft: -95,
    top: "40%",
    "&:hover": {
      color: "#27b279",
      cursor: "pointer",
    },
  },
});

function Rifa() {
  const { username, uuid } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const [topData, setTopData] = useState([]);
  const [soldNumbers, setSoldNumbers] = useState([]);
  const [buyTicket, setBuyTicket] = useState(false);

  const [dataRifa, setDataRifa] = useState({});
  const [itemsToShow, setItemsToShow] = useState(2);
  const [buyType, setBuyType] = useState(2);
  const [loading, setLoading] = useState(true);

  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [modalCheckout, setModalCheckout] = useState(false);
  const [isDisabledButton, setDisabledButton] = useState(false);

  const godColor = "#FFD700";
  const silverColor = "#C0C0C0";
  const bronColor = "#CE8946";

  const carouselRef = useRef(null);

  let resetTimeout;

  const setModalsetBuyTicket = (value) => {
    setDisabledButton(true);
    setBuyTicket(value);
    setTimeout(() => {
      setDisabledButton(false);
    }, 1000);
  };

  const [dataForm, setDataForm] = useState({
    amount: 1,
    custumer_phone: undefined,
    user: undefined,
    unitValue: 0,
    value: 0,
    ticket_id: "",
    selectedNumbers: [],
  });

  const getRifa = () => {
    getRifaFromAPI(username, uuid)
      .then(async (res) => {
        if (res) setDataRifa(res);

        if (res?.data?.ticket?.buy_type == 2) {
          setDataForm({
            ...dataForm,
            value: 0,
            unitValue: res?.data?.ticket?.price,
            user: res?.data?.ticket?.user,
            ticket_id: res?.data?.ticket?.id,
            amount: 0,
          });
        } else {
          setDataForm({
            ...dataForm,
            value: Number(res?.data?.ticket?.price),
            unitValue: res?.data?.ticket?.price,
            user: res?.data?.ticket?.user,
            ticket_id: res?.data?.ticket?.id,
          });
        }

        setBuyType(res?.data?.ticket?.buy_type);
        setSoldNumbers(res?.data?.sold);

        await getTopBuyers(res?.data?.ticket?.id);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
  };

  const getTopBuyers = async (id) => {
    await getTicketTopBuys(id)
      .then((res) => {
        setTopData(res);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getRifa();
  }, []);

  useEffect(() => {
    const updateItemsToShow = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setItemsToShow(1);
      } else if (width < 900) {
        setItemsToShow(2);
      } else if (width < 1200) {
        setItemsToShow(3);
      } else {
        setItemsToShow(4);
      }
    };

    updateItemsToShow(); // Chama a função na montagem do componente

    window.addEventListener("resize", updateItemsToShow); // Adiciona um listener para o resize da tela

    return () => window.removeEventListener("resize", updateItemsToShow); // Limpa o listener ao desmontar o componente
  }, []);

  const updateTicketsNumber = (type, value, actual) => {
    switch (type) {
      case "ADD":
        if (actual >= dataRifa?.data?.ticket?.total_tickets) {
          const sumPrice = dataForm.unitValue * actual;
          setDataForm({
            ...dataForm,
            amount: dataRifa?.data?.ticket?.total_tickets,
            value: sumPrice,
          });
        } else {
          const sum = actual + value;
          const sumPrice = dataForm.unitValue * sum;
          if (sum >= dataRifa?.data?.ticket?.total_tickets) {
            const sumPrice =
              dataForm.unitValue * dataRifa?.data?.ticket?.total_tickets;
            setDataForm({
              ...dataForm,
              amount: dataRifa?.data?.ticket?.total_tickets,
              value: sumPrice,
            });
          } else {
            setDataForm({ ...dataForm, amount: sum, value: sumPrice });
          }
        }

        break;
      case "REMOVE":
        if (buyType == 1) {
          if (actual <= 1) {
            const subPrice = dataForm.unitValue * actual;
            setDataForm({ ...dataForm, amount: 1, value: subPrice });
          } else {
            const sub = actual - value;
            const subPrice = dataForm.unitValue * sub;
            console.log(sub);
            setDataForm({ ...dataForm, amount: sub, value: subPrice });
          }
        }
        if (buyType == 2) {
          if (actual <= 0) {
            const subPrice = dataForm.unitValue * actual;
            setDataForm({ ...dataForm, amount: 0, value: subPrice });
          } else {
            const sub = actual - value;
            const subPrice = dataForm.unitValue * sub;
            console.log(sub);
            setDataForm({ ...dataForm, amount: sub, value: subPrice });
          }
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (value) => {
    setDataForm({ ...dataForm, custumer_phone: value });
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = Number(value);

    if (amount <= 0) {
      const sumPrice = dataForm.unitValue * 1;
      setDataForm({
        ...dataForm,
        amount: 1,
        value: sumPrice,
      });
    } else {
      const maxTickets = dataRifa?.data?.ticket?.initial_total_tickets || 0;
      const sumPrice =
        dataForm.unitValue * Number(amount >= maxTickets ? maxTickets : amount);
      setDataForm({
        ...dataForm,
        amount: amount >= maxTickets ? maxTickets : amount,
        value: sumPrice,
      });
    }
  };

  const handleSelection = (numbers) => {
    setSelectedNumbers(numbers);
  };

  useEffect(() => {
    setDataForm({
      ...dataForm,
      selectedNumbers: selectedNumbers,
    });
  }, [selectedNumbers]);

  return (
    <>
      {dataRifa?.data?.ticket && (
        <Seo
          title={`${dataRifa?.data?.ticket?.title} | Bilhete.app`}
          keywords={dataRifa?.data?.ticket?.title}
          url={shareUrl}
          description={stripHTML(dataRifa?.data?.ticket?.desc)}
          image={dataRifa?.data?.ticket?.img1 || noImgage}
        />
      )}
      <Backdrop
        sx={(theme) => ({
          background: "#ffffff4d",
          zIndex: theme.zIndex.drawer + 99999,
          backdropFilter: "blur(10px)",
        })}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Backdrop
        sx={(theme) => ({
          color: "#fff",
          background: "#ffffff4d",
          zIndex: theme.zIndex.drawer + 99999,
          backdropFilter: "blur(10px)",
        })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CheckoutModal
        open={modalCheckout}
        handleClose={() => setModalCheckout(false)}
        formData={dataForm}
        handleChange={handleChange}
        selectedNumbers={selectedNumbers}
      />
      <Grid container direction="row" justifyContent="center">
        <HeaderMenu />
        <Grid item xs="12">
          <ReactElasticCarousel
            isRTL={false}
            itemsToScroll={1}
            itemsToShow={itemsToShow}
            //initialFirstItem={modalIndex}
            renderPagination={({ pages, activePage, onClick }) => {
              return (
                <Grid container direction="row" justifyContent="center">
                  <Grid
                    item
                    style={{
                      backgroundColor: "#FFF",
                      position: "absolute",
                      zIndex: 3,
                      marginTop: -70,
                      borderRadius: 7,
                    }}
                  >
                    {pages.map((page) => {
                      const isActivePage = activePage === page;
                      return (
                        <IconButton key={page} onClick={() => onClick(page)}>
                          {isActivePage ? (
                            <Adjust color="primary" sx={{ fontSize: 20 }} />
                          ) : (
                            <Circle color="primary" sx={{ fontSize: 15 }} />
                          )}
                        </IconButton>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            }}
            renderArrow={({ type, onClick }) => (
              <>
                {type === "PREV" ? (
                  <div onClick={onClick} className={classes.arrowsBoxLeft}>
                    <IconButton className={classes.arrowsLeft}>
                      <KeyboardArrowLeft sx={{ fontSize: 50 }} />
                    </IconButton>
                  </div>
                ) : (
                  <div onClick={onClick} className={classes.arrowsBoxRight}>
                    <IconButton className={classes.arrowsRight}>
                      <KeyboardArrowRight sx={{ fontSize: 50 }} />
                    </IconButton>
                  </div>
                )}
              </>
            )}
          >
            {dataRifa?.data?.ticket?.img1 && (
              <div>
                <img src={dataRifa?.data?.ticket?.img1} width="100%" />
              </div>
            )}

            {dataRifa?.data?.ticket?.img2 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img2} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img3 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img3} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img4 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img4} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img5 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img5} width="100%" />
              </div>
            )}
            {dataRifa?.data?.ticket?.img6 && (
              <div style={{}}>
                <img src={dataRifa?.data?.ticket?.img6} width="100%" />
              </div>
            )}
            {!dataRifa?.data?.ticket?.img1 &&
              !dataRifa?.data?.ticket?.img2 &&
              !dataRifa?.data?.ticket?.img3 &&
              !dataRifa?.data?.ticket?.img4 &&
              !dataRifa?.data?.ticket?.img5 &&
              !dataRifa?.data?.ticket?.img6 && (
                <div style={{}}>
                  <img src={noImgage} width="100%" />
                </div>
              )}
          </ReactElasticCarousel>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ mt: -5, zIndex: 2, position: "relative", pl: 2, pr: 2 }}
      >
        <Grid item xs={12} sm={8} md={5}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs="12">
              <Card elevation={20}>
                <CardContent
                  sx={{
                    paddingTop: 1.5,
                  }}
                >
                  {/* 
                  {JSON.stringify(dataRifa)} */}
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 800,
                      fontSize: 27,
                      lineHeight: "25px",
                    }}
                  >
                    {dataRifa?.data?.ticket?.title}
                  </Typography>
                  <Divider />
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              pt: 1,
                              mr: 1,
                            }}
                          >
                            Número de bilhetes:
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          sx={{
                            mt: -1.4,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 800,
                              fontSize: 30,
                              color: theme.palette.primary.main,
                            }}
                          >
                            <NumericFormat
                              value={
                                dataRifa?.data?.ticket?.initial_total_tickets
                              }
                              decimalScale={2}
                              decimalSeparator=","
                              displayType="text"
                              thousandSeparator="."
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              pt: 1,
                              mr: 1,
                            }}
                          >
                            Cada bilhete:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              fontSize: 15,
                              pt: 0.4,
                              mr: 0.5,
                              color: theme.palette.primary.main,
                            }}
                          >
                            R$
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            mt: -1.4,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 900,
                              fontSize: 30,
                              color: theme.palette.primary.main,
                            }}
                          >
                            <NumericFormat
                              value={dataRifa?.data?.ticket?.price}
                              decimalScale={2}
                              decimalSeparator=","
                              displayType="text"
                              fixedDecimalScale
                              thousandSeparator="."
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      mt: -0.5,
                    }}
                  />
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              pt: 1,
                              mr: 1,
                            }}
                          >
                            Data do Sorteio:
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              color: theme.palette.primary.main,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 15,
                                fontWeight: 800,
                                paddingTop: 3,
                                paddingLeft: 6,
                                paddingRight: 6,
                                display: "flex",
                                justifyContent: "center",
                                float: "left",
                                alignItems: "center",
                                borderRadius: 9,
                                background: "rgb(232 232 232)",
                              }}
                            >
                              <DateRange
                                sx={{ fontSize: 16, mt: -0.5, pr: 1 }}
                              />
                              <Grid>
                                {formatDateRelease(
                                  dataRifa?.data?.ticket?.release_date,
                                  " dd/MM/yyyy"
                                )}
                              </Grid>
                            </div>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: 16,
                              pt: 1,
                              mr: 1,
                            }}
                          >
                            Local:
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              fontSize: 35,
                              color: theme.palette.primary.main,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 15,
                                fontWeight: 800,
                                paddingTop: 3,
                                paddingLeft: 6,
                                paddingRight: 6,
                                display: "flex",
                                justifyContent: "center",
                                float: "left",
                                alignItems: "center",
                                borderRadius: 9,
                                background: "rgb(232 232 232)",
                              }}
                            >
                              <IconDisplay
                                value={dataRifa?.data?.ticket?.type}
                              />

                              <Grid>
                                {getTypeTicket(dataRifa?.data?.ticket?.type)}
                              </Grid>
                            </div>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {dataRifa?.data?.ticket?.tickets_award.length >= 1 && (
              <Grid item xs="12">
                <Card elevation={20}>
                  <CardHeader title="Bilhetes Premiados" />
                  <CardContent>
                    <Grid
                      container
                      xs={12}
                      style={{
                        maxHeight: 200,
                        overflowY: "auto",
                      }}
                    >
                      {dataRifa?.data?.ticket?.tickets_award.map(
                        (item, index) => (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            style={{
                              display: "flex",
                              gap: 8,
                              marginBottom: 10,
                            }}
                          >
                            <Grid
                              item
                              style={{
                                background: theme.palette.primary.main,
                                fontWeight: 900,
                                color: "white",
                                padding: 5,
                                borderRadius: 5,
                              }}
                            >
                              <NumericFormat
                                value={item.number}
                                decimalScale={2}
                                decimalSeparator=","
                                displayType="text"
                                thousandSeparator="."
                              />
                            </Grid>
                            <Grid
                              item
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              {item.description || "Nenhuma descrição"}
                            </Grid>
                            <Divider />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid item xs="12">
              <Card elevation={20}>
                <CardHeader title="Descrição" />
                <CardContent>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(dataRifa?.data?.ticket?.desc),
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            {dataRifa?.data?.ticket?.show_ranking && (
              <Grid item xs="12">
                <Grid item xs="12">
                  <ReactElasticCarousel
                    ref={carouselRef}
                    isRTL={false}
                    autoPlaySpeed={3000} // same time
                    itemsToScroll={1}
                    enableAutoPlay={true}
                    onNextEnd={({ index }) => {
                      clearTimeout(resetTimeout);
                      if (index + 1 === 3) {
                        resetTimeout = setTimeout(() => {
                          carouselRef.current.goTo(0);
                        }, 3000); // same time
                      }
                    }}
                    itemsToShow={1}
                    pagination={false}
                    //initialFirstItem={modalIndex}
                    renderPagination={({ pages, activePage, onClick }) => {
                      return (
                        <Grid container direction="row" justifyContent="center">
                          <Grid
                            item
                            style={{
                              backgroundColor: "#FFF",
                              position: "absolute",
                              zIndex: 3,
                              marginTop: -70,
                              borderRadius: 7,
                            }}
                          >
                            {pages.map((page) => {
                              const isActivePage = activePage === page;
                              return (
                                <IconButton
                                  key={page}
                                  onClick={() => onClick(page)}
                                >
                                  {isActivePage ? (
                                    <Adjust
                                      color="primary"
                                      sx={{ fontSize: 20 }}
                                    />
                                  ) : (
                                    <Circle
                                      color="primary"
                                      sx={{ fontSize: 15 }}
                                    />
                                  )}
                                </IconButton>
                              );
                            })}
                          </Grid>
                        </Grid>
                      );
                    }}
                    renderArrow={({ type, onClick }) => (
                      <>
                        {type === "PREV" ? (
                          <div
                            onClick={onClick}
                            className={classes.arrowsBoxLeft}
                          >
                            <IconButton className={classes.arrowsLeft}>
                              <KeyboardArrowLeft sx={{ fontSize: 50 }} />
                            </IconButton>
                          </div>
                        ) : (
                          <div
                            onClick={onClick}
                            className={classes.arrowsBoxRight}
                          >
                            <IconButton className={classes.arrowsRight}>
                              <KeyboardArrowRight sx={{ fontSize: 50 }} />
                            </IconButton>
                          </div>
                        )}
                      </>
                    )}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              background: godColor,
                            }}
                          >
                            <CardContent className="box-ranking">
                              <CardHeaderRanking title="Ouro" sx={{ mb: -2 }} />
                              <Grid item xs={12}>
                                <Grid container justifyContent="center" xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: 900,
                                      fontSize: 20,
                                      mb: -1.5,
                                      color: "white",
                                      textShadow: "2px 2px 10px #000000",
                                    }}
                                  >
                                    1º Lugar
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sx={{
                                  mt: 4,
                                }}
                              >
                                <Grid container justifyContent="center" xs={12}>
                                  <div className="ticketContainerTop">
                                    <div className="ticketTop">
                                      <div className="ticketTitleTopRanking">
                                        <Star
                                          sx={{
                                            color: godColor,
                                          }}
                                        />
                                        <Star
                                          sx={{
                                            color: godColor,
                                          }}
                                        />
                                        <Star
                                          sx={{
                                            color: godColor,
                                          }}
                                        />
                                      </div>
                                      <div className="ticketTitleTop">
                                        <Typography
                                          sx={{
                                            fontWeight: 500,
                                            fontSize: 17,
                                            color: theme.palette.primary.main,
                                          }}
                                        >
                                          {topData[0]?.custumer_name ||
                                            "Ninguém ainda!"}
                                        </Typography>
                                      </div>
                                      <Divider />
                                      <div className="ticketDetailTop">
                                        <div className="ticketDetailCenter">
                                          Comprou
                                        </div>
                                        <div className="ticketDetailCenter">
                                          <Typography
                                            sx={{
                                              fontWeight: 900,
                                              fontSize: 30,
                                              color: theme.palette.primary.main,
                                              mb: -0.9,
                                            }}
                                          >
                                            {topData[0]?.amount || "0"}
                                          </Typography>
                                        </div>
                                        <div className="ticketDetailCenter">
                                          {topData[0]?.amount === 1
                                            ? "Bilhete"
                                            : "Bilhetes"}
                                        </div>
                                      </div>
                                      <div className="ticketRipTop">
                                        <div className="circleLeftTopGold"></div>
                                        <div className="ripLineTopGold"></div>
                                        <div className="circleRightTopGold"></div>
                                      </div>
                                      <div className="ticketSubDetailTop">
                                        <div className="codeTop">
                                          Bilhete.app
                                        </div>
                                        <div className="dateTop">
                                          <img src={trofeuOuro} alt="Logo" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ticketShadowTop"></div>
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>

                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              background: silverColor,
                            }}
                          >
                            <CardContent className="box-ranking">
                              <CardHeaderRanking
                                title="Prata"
                                sx={{ mb: -2 }}
                              />
                              <Grid item xs={12}>
                                <Grid container justifyContent="center" xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: 900,
                                      fontSize: 20,
                                      mb: -1.5,
                                      color: "white",
                                      textShadow: "2px 2px 10px #000000",
                                    }}
                                  >
                                    2º Lugar
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sx={{
                                  mt: 4,
                                }}
                              >
                                <Grid container justifyContent="center" xs={12}>
                                  <div className="ticketContainerTop">
                                    <div className="ticketTop">
                                      <div className="ticketTitleTopRanking">
                                        <Star
                                          sx={{
                                            color: silverColor,
                                          }}
                                        />
                                        <Star
                                          sx={{
                                            color: silverColor,
                                          }}
                                        />
                                        <StarBorder
                                          sx={{
                                            color: silverColor,
                                          }}
                                        />
                                      </div>
                                      <div className="ticketTitleTop">
                                        <Typography
                                          sx={{
                                            fontWeight: 500,
                                            fontSize: 17,
                                            color: theme.palette.primary.main,
                                          }}
                                        >
                                          {topData[1]?.custumer_name ||
                                            "Ninguém ainda!"}
                                        </Typography>
                                      </div>
                                      <Divider />
                                      <div className="ticketDetailTop">
                                        <div className="ticketDetailCenter">
                                          Comprou
                                        </div>
                                        <div className="ticketDetailCenter">
                                          <Typography
                                            sx={{
                                              fontWeight: 900,
                                              fontSize: 30,
                                              color: theme.palette.primary.main,
                                              mb: -0.9,
                                            }}
                                          >
                                            {topData[1]?.amount || "0"}
                                          </Typography>
                                        </div>
                                        <div className="ticketDetailCenter">
                                          {topData[1]?.amount === 1
                                            ? "Bilhete"
                                            : "Bilhetes"}
                                        </div>
                                      </div>
                                      <div className="ticketRipTop">
                                        <div className="circleLeftTopBron"></div>
                                        <div className="ripLineTopBron"></div>
                                        <div className="circleRightTopBron"></div>
                                      </div>
                                      <div className="ticketSubDetailTop">
                                        <div className="codeTop">
                                          Bilhete.app
                                        </div>
                                        <div className="dateTop">
                                          <img src={trofeuPrata} alt="Logo" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ticketShadowTop"></div>
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>

                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Card
                            sx={{
                              background: bronColor,
                            }}
                          >
                            <CardContent className="box-ranking">
                              <CardHeaderRanking
                                title="Bronze"
                                sx={{ mb: -2 }}
                              />
                              <Grid item xs={12}>
                                <Grid container justifyContent="center" xs={12}>
                                  <Typography
                                    sx={{
                                      fontWeight: 900,
                                      fontSize: 20,
                                      mb: -1.5,
                                      color: "white",
                                      textShadow: "2px 2px 10px #000000",
                                    }}
                                  >
                                    3º Lugar
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sx={{
                                  mt: 4,
                                }}
                              >
                                <Grid container justifyContent="center" xs={12}>
                                  <div className="ticketContainerTop">
                                    <div className="ticketTop">
                                      <div className="ticketTitleTopRanking">
                                        <Star
                                          sx={{
                                            color: bronColor,
                                          }}
                                        />
                                        <StarBorder
                                          sx={{
                                            color: bronColor,
                                          }}
                                        />
                                        <StarBorder
                                          sx={{
                                            color: bronColor,
                                          }}
                                        />
                                      </div>
                                      <div className="ticketTitleTop">
                                        <Typography
                                          sx={{
                                            fontWeight: 500,
                                            fontSize: 17,
                                            color: theme.palette.primary.main,
                                          }}
                                        >
                                          {topData[2]?.custumer_name ||
                                            "Ninguém ainda!"}
                                        </Typography>
                                      </div>
                                      <Divider />
                                      <div className="ticketDetailTop">
                                        <div className="ticketDetailCenter">
                                          Comprou
                                        </div>
                                        <div className="ticketDetailCenter">
                                          <Typography
                                            sx={{
                                              fontWeight: 900,
                                              fontSize: 30,
                                              color: theme.palette.primary.main,
                                              mb: -0.9,
                                            }}
                                          >
                                            {topData[2]?.amount || "0"}
                                          </Typography>
                                        </div>
                                        <div className="ticketDetailCenter">
                                          {topData[2]?.amount === 1
                                            ? "Bilhete"
                                            : "Bilhetes"}
                                        </div>
                                      </div>
                                      <div className="ticketRipTop">
                                        <div className="circleLeftTopSilver"></div>
                                        <div className="ripLineTopSilver"></div>
                                        <div className="circleRightTopSilver"></div>
                                      </div>
                                      <div className="ticketSubDetailTop">
                                        <div className="codeTop">
                                          Bilhete.app
                                        </div>
                                        <div className="dateTop">
                                          <img src={trofeuBron} alt="Logo" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ticketShadowTop"></div>
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
                  </ReactElasticCarousel>
                </Grid>
              </Grid>
            )}
            <Grid item xs="12">
              <Card elevation={20}>
                <CardHeader title="Organizador" />
                <CardContent>
                  <Grid container direction="row">
                    <Grid item xs={12} display="flex">
                      <Grid item>
                        <Avatar
                          alt={dataRifa?.data?.user?.name}
                          src={dataRifa?.data?.user?.image}
                          sx={{
                            width: 56,
                            height: 56,
                            border: `3px solid ${theme.palette.primary.main}`,
                          }}
                        />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        style={{ paddingLeft: 10 }}
                        gap={1}
                      >
                        <Grid item xs={12} display="flex">
                          <Typography
                            variant="div"
                            style={{
                              fontSize: 20,
                              fontWeight: 600,
                              color: theme.palette.primary.main,
                            }}
                          >
                            {dataRifa?.data?.user?.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexWrap="wrap"
                          gap={1}
                        >
                          {dataRifa?.data?.user?.networks.map((item, index) => (
                            <NetworksButton type={item.type} url={item.url} />
                          ))}
                          {dataRifa?.data?.user?.networks?.length <= 0 && (
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontWeight: 900,
                                  color: theme.palette.primary.main,
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 800,
                                    paddingTop: 3,
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    display: "flex",
                                    justifyContent: "center",
                                    float: "left",
                                    alignItems: "center",
                                    borderRadius: 9,
                                    background: "rgb(232 232 232)",
                                  }}
                                >
                                  <FaLink
                                    sx={{ fontSize: 16, mt: -0.5, mr: 1 }}
                                  />
                                  <Grid
                                    sx={{
                                      pl: 0.5,
                                    }}
                                  >
                                    Nenhum link cadastrado!
                                  </Grid>
                                </div>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs="12">
              <Card elevation={20}>
                <CardHeader title="Compartilhar" />
                <CardContent>
                  <Grid container direction="row">
                    <Grid item xs={12} display="flex">
                      <Grid
                        container
                        direction="row"
                        style={{ paddingLeft: 10 }}
                        gap={1}
                      >
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          flexWrap="wrap"
                          gap={1}
                        >
                          <FacebookShareButton
                            url={shareUrl}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>

                          <FacebookMessengerShareButton
                            url={shareUrl}
                            appId="521270401588372"
                            className="Demo__some-network__share-button"
                          >
                            <FacebookMessengerIcon size={32} round />
                          </FacebookMessengerShareButton>

                          <TwitterShareButton
                            url={shareUrl}
                            title={`Rifa: ${dataRifa?.data?.ticket?.title}`}
                            className="Demo__some-network__share-button"
                          >
                            <XIcon size={32} round />
                          </TwitterShareButton>

                          <TelegramShareButton
                            url={shareUrl}
                            title={`Rifa: ${dataRifa?.data?.ticket?.title}`}
                            className="Demo__some-network__share-button"
                          >
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>

                          <WhatsappShareButton
                            url={shareUrl}
                            title={`Rifa: ${dataRifa?.data?.ticket?.title}`}
                            separator=":: "
                            className="Demo__some-network__share-button"
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer theme={theme} />
      {buyTicket && (
        <Grid
          container
          direction="row"
          sx={{
            height: 350,
          }}
        ></Grid>
      )}

      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{
          mt: -3,
          zIndex: 4,
          position: "fixed",
          bottom: 0,
        }}
      >
        <Grid item xs={12} sm={8} md={5}>
          <Card
            elevation={20}
            sx={{
              backdropFilter: "blur(10px)",
              background: "#ffffffc9",
            }}
          >
            {buyType == 1 && (
              <BuyTicketContent
                buyTicket={buyTicket}
                setBuyTicket={setBuyTicket}
                updateTicketsNumber={updateTicketsNumber}
                handleAmountChange={handleAmountChange}
                dataForm={dataForm}
                dataRifa={dataRifa}
                setModalCheckout={setModalCheckout}
                setModalsetBuyTicket={setModalsetBuyTicket}
                isDisabledButton={isDisabledButton}
              />
            )}

            {buyType == 2 && (
              <NumberSelector
                buyTicket={buyTicket}
                dataRifa={dataRifa}
                dataForm={dataForm}
                setBuyTicket={setBuyTicket}
                maxNumber={dataRifa?.data?.ticket?.initial_total_tickets}
                setModalsetBuyTicket={setModalsetBuyTicket}
                onSelect={handleSelection}
                soldNumbers={soldNumbers}
                isDisabledButton={isDisabledButton}
                setModalCheckout={setModalCheckout}
                updateTicketsNumber={updateTicketsNumber}
                buyType={buyType}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Rifa;
