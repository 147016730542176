import PlaceIcon from "@mui/icons-material/Place"; // Para "Nenhum local"
import LocalActivityIcon from "@mui/icons-material/LocalActivity"; // Para "Loteria Federal"
import InstagramIcon from "@mui/icons-material/Instagram"; // Para "Live no Instagram"
import FacebookIcon from "@mui/icons-material/Facebook"; // Para "Live no Facebook"
import TikTokIcon from "@mui/icons-material/Movie"; // TikTok (não tem ícone específico)
import LiveTvIcon from "@mui/icons-material/LiveTv"; // Para "Live"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; // Para "Outros"

export function getTypeTicket(value) {
  switch (value) {
    case 0:
      return "Nenhum local";
    case 1:
      return "Loteria Federal";
    case 2:
      return "Live no Instagram";
    case 3:
      return "Live no Facebook";
    case 4:
      return "Live no Tiktok";
    case 5:
      return "Live";
    case 6:
      return "Outros";
    default:
      return "Outros";
  }
}

function getIconByValue(value) {
  switch (value) {
    case 0:
      return <PlaceIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />;
    case 1:
      return <LocalActivityIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />;
    case 2:
      return <InstagramIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />;
    case 3:
      return <FacebookIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />;
    case 4:
      return <TikTokIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />;
    case 5:
      return <LiveTvIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />;
    case 6:
      return <MoreHorizIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />;
    default:
      return <MoreHorizIcon sx={{ fontSize: 16, mt: -0.5, pr: 1 }} />; // Ícone padrão
  }
}

export function IconDisplay({ value }) {
  return getIconByValue(value);
}
