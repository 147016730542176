import { CardHeader as MuiCardHeader } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardHeaderRanking = styled(MuiCardHeader)(({ theme }) => ({
  fontFamily: "'Zain', san-serif",
  "& .MuiCardHeader-content": {
    display: "flex",
  },
  "& .MuiCardHeader-content .MuiCardHeader-title": {
    fontFamily: "'Zain', san-serif",
    fontSize: 20,
    fontWeight: 800,
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    borderRadius: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: -70,
    marginTop: -30,
  },
}));
